import { string } from 'prop-types';
import React, { useState } from 'react';
import { OutsideClickHandler, RangeSlider } from '../../../../components';
import LandingServicesPage from '../../LandingServicesPage';
import css from './LatestServices.module.css';
import { classNames } from 'primereact/utils';

const LatestServices = ({ organizedCategories, intl, latestServicesClass }) => {
  const [showPriceRange, setShowPriceRange] = useState(false);
  const [priceRange, setPriceRange] = useState({ min: 0, max: 10000 });
  const [category, setCategory] = useState('');
  const [currLocation, setCurrLocation] = useState({});
  const [nearby, setNearby] = useState('');
  const arabicLanguage = typeof window !== 'undefined' && localStorage.getItem("language") === "Arabic";

  const handleNearBySubmit = e => {
    if (e.target.value == 'current') {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const { latitude, longitude } = position.coords;
            setCurrLocation({ lat: latitude.toFixed(3), longitude: longitude.toFixed(3) });
          },
          error => {
            console.error('Error fetching location:', error);
          }
        );
      }
    } else {
      setCurrLocation({});
    }
    setNearby(e.target.value);
  };
  return (
    <div className={classNames(css.latestServicesWrapper , arabicLanguage && css.arabicDirection)}>
      <div className={classNames(css.searvicesBox)}>
        <div className={css.servicesHeading}>
          {intl.formatMessage({ id: "LatestServices.heading" })}
        </div>
        <div className={css.searvicesFilter}>
          <div className={css.selectBox}>
            {organizedCategories?.length > 0 && (
              <select
                value={category}
                onChange={e => setCategory(e.target.value)}
              // className={css.dropdown}
              >
                <option value="">
                  {intl.formatMessage({ id: "LatestServices.categoriesFilter" })}
                </option>
                {organizedCategories.map(value => (
                  <option key={value.id} value={value.id}>
                    {intl.formatMessage({ id: `category.${value.id}` })}
                  </option>
                ))}
              </select>
            )}
          </div>

          <div className={css.selectBox}>
            <div className={css.priceRange}>
              <label onClick={() => setShowPriceRange(true)}>
                {intl.formatMessage({ id: "LatestServices.budgetFilter" })}
              </label>
              <OutsideClickHandler onOutsideClick={() => setShowPriceRange(false)}>
                {showPriceRange && (
                  <div className={css.priceInput}>
                    <div>
                      <RangeSlider
                        min={0}
                        max={10000}
                        step={100}
                        handles={[priceRange.min, priceRange.max]}
                        onChange={handles => {
                          setPriceRange({ min: handles[0], max: handles[1] });
                        }}
                      />
                    </div>
                    <span>
                      {priceRange.min} - {priceRange.max}
                    </span>
                  </div>
                )}
              </OutsideClickHandler>
            </div>
          </div>

          <div className={css.selectBox}>
            <select
              value={nearby}
              onChange={e => handleNearBySubmit(e)}
            // className={css.dropdown}
            >
              <option value="">
                {intl.formatMessage({ id: "LatestServices.nearByFilter" })}
              </option>

              <option value="current">
                {intl.formatMessage({ id: "LatestServices.currentLocation" })}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div className={classNames(css.searvicesCard, latestServicesClass)}>
        <LandingServicesPage
          currLocation={currLocation}
          priceRange={priceRange}
          category={category}
        />
      </div>
    </div>
  );
};

LatestServices.defaultProps = {
  className: null,
  rootClassName: null,
};

LatestServices.propTypes = {
  className: string,
  rootClassName: string,
};

export default LatestServices;
